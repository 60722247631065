import React, { Component } from 'react'
import { navigate } from 'gatsby'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import Dropzone from 'react-dropzone'

import Upload from '../assets/images/upload.svg'
import Seo from '../components/seo'

/*
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
*/
const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k)=>{
    formData.append(k,data[k])
  });
  return formData
}

class Form extends Component {
  state = {
    dateString: 'Date of Purchase',
    files: [],
  }

  handleChange = e => {
    if (!e.target?.name) {
      this.setState({ 'date': moment(e).format('D MMM YY') })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  onDrop = (files) => {
    this.setState({
      files: files,
      'file_upload': files[0]
    })
  }

  handleDateChange = e => {
    this.setState({ 'date_of_purchase': moment(e).format('D MMM YY') })
    this.setState({ dateString: moment(e).format('D MMM YY') })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      //headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //headers: { "Content-Type": "multipart/form-data" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        navigate('/thank-you/')
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {
    let props = {
      ref: "form",
      name: "warranty_registration",
      className: "form form--flex",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    let { files } = this.state

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for getting in contact with us.
          <br /> A member of our team will be in touch soon.
        </p>
      )

    return (
      <form {...props}>
        <div className='form__column'>
          <h4>Your Details</h4>
          <div className="form__row form__row--double">
            <div className='form__col'>
              <input
                type="text"
                name="first_name"
                placeholder="First Name*"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className='form__col'>
              <input
                type="text"
                name="last_name"
                placeholder="Last Name*"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="form__row form__row--double">
            <div className='form__col'>
              <input
                type="email"
                name="email"
                placeholder="Your Email*"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className='form__col'>
              <input
                type="text"
                name="phone"
                placeholder="Phone number"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form__row">
            <input
              type="text"
              name="address"
              placeholder="Address"
              onChange={this.handleChange}
            />
          </div>
          <div className='form__row form__row--address'>
            <div className='form__col'>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                onChange={this.handleChange}
              />
            </div>
            <div className='form__col form__select'>
              <select
                name='state'
                onChange={this.handleChange}>
                <option value=''>State</option>
                <option value='VIC'>VIC</option>
                <option value='NSW'>NSW</option>
                <option value='NT'>NT</option>
                <option value='QLD'>QLD</option>
                <option value='SA'>SA</option>
                <option value='TAS'>TAS</option>
                <option value='WA'>WA</option>
              </select>
            </div>
            <div className='form__col'>
              <input
                type="text"
                name="postcode"
                placeholder="Postcode"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className='form__row'>
            <textarea
              type="text"
              name="message"
              placeholder="Message (optional)"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form__column'>
          <h4>Your Lenoxx Purchase</h4>
          <div className="form__row form__row--double">
            <div className='form__col'>
              <input
                type="text"
                name="model_number"
                placeholder="Model Number (if known)"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className='form__col'>
              <DatePicker onChange={this.handleDateChange} name='date_of_purchase' placeholderText='Date of Purchase' value={this.state.dateString} dateFormat="DD/MM/YYYY" />
            </div>
          </div>
          <div className='form__row'>
            <p>Please upload an image of your receipt for proof of purchase, or your warranty claim may be denied.</p>
            <Dropzone onDrop={this.onDrop} multiple={false}>
              {({getRootProps, getInputProps}) => (
                <div  {...getRootProps({className: 'dropzone form__dropzone'})}>
                  <div className='form__file'>
                    <input name='file_upload' {...getInputProps()} />
                    <img src={Upload} alt='Upload File' />
                    <p>Drag and drop<br />
                    Or browse to choose a file</p>
                    { files && files.length === 0 && <span>.png, .jpg, .pdf accepted, up to 6MB</span> }
                    { files && files.length > 0 && files.map((file, i) =>
                      <div key={i}>{file.name} - {file.size} bytes</div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="form__row form__row--triple">
            <p>How would you rate…</p>
            <div className='form__col form__select'>
              <select
                name='our_prices'
                onChange={this.handleChange}>
                <option value=''>Our prices?</option>
                <option value='excellent'>Excellent</option>
                <option value='great'>Great</option>
                <option value='good'>Good</option>
                <option value='fair'>Fair</option>
                <option value='poor'>Poor</option>
              </select>
            </div>
            <div className='form__col form__select'>
              <select
                name='our_range'
                onChange={this.handleChange}>
                <option value=''>Our range?</option>
                <option value='excellent'>Excellent</option>
                <option value='great'>Great</option>
                <option value='good'>Good</option>
                <option value='fair'>Fair</option>
                <option value='poor'>Poor</option>
              </select>
            </div>
            <div className="form__col form__select">
              <select
                name='our_product_quality'
                onChange={this.handleChange}>
                <option value=''>Our product quality?</option>
                <option value='excellent'>Excellent</option>
                <option value='great'>Great</option>
                <option value='good'>Good</option>
                <option value='fair'>Fair</option>
                <option value='poor'>Poor</option>
              </select>
            </div>
          </div>
          <div className="form__row form__row--submit form__row--full">
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    )
  }
}

class WarrantyRegistrationPage extends Component {

  render() {
    return (
      <>
        <Seo title='Warranty Registration - Lenoxx' description='' />
        <section className='basic'>
          <div className='basic__inner'>
            <div className='basic__column'>
              <h1>Warranty Registration</h1>
              <p>Register your Lenoxx purchase below.</p>
            </div>
          </div>
          <div className='basic__inner'>
            <Form />
          </div>
        </section>
      </>
    )
  }
}

export default WarrantyRegistrationPage
